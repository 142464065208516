<template>
  <div class="wallet-fx">
    <div class="container-fluid mt--5">
      <div class="card mb-3">
        <div class="card-header">
          <h3 class="mb-0">Auto FX Between your Account</h3>
        </div>
        <div class="card-body align-items-center" v-loading="loading">
          <div class="row mb-6" v-if="kycStatus && kycStatus != 'approve'">
            <el-alert
                :title="`KYC Status ${kycStatus}`"
                type="error"
                :description="'Please finish your KYC on AB app'"
                :closable="false"
                
                >
              </el-alert>
          </div>
          
          <div class="row justify-content-md-center" v-if="rateofftime">
            <div class="col-lg-12 col-md-12 text-center">
              <img
                :src="'/img/brand/logo.png'"
                class="navbar-brand-img"
                alt="logo"
                width="320px"
              />
              <div >
                    <h1 class="mt-5">Services is unavailable during the office closed</h1>
                    <h3>Please used this function between 9.00-17.00 between Monday - Friday</h3>
                  </div>
            </div>
          </div>
          <div class="row justify-content-md-center" v-if="kycStatus && kycStatus == 'approve' &&!rateofftime">
            <div class="col-12 col-lg-6 col-xl-4">
              <div class="card p-2 mb-3 card-wallet">
                <div class="card-header p-1 border-0">
                  <h4 class="mb-0">From</h4>
                </div>
                <wallet-select
                  ref="walletFrom"
                  :ignore-currency="wallet_to_currency"
                  @onChange="selectFrom"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-4">
              <div class="card p-2 mb-3 card-wallet">
                <div class="card-header p-1 border-0">
                  <h4 class="mb-0">To</h4>
                </div>
                <wallet-select
                  :ignore-currency="wallet_from_currency"
                  @onChange="selectTo"
                />
              </div>
            </div>
            <div
              v-if="wallet_from_currency && wallet_to_currency"
              class="col-12 col-lg-8 col-xl-6"
            >
              <div class="card p-2 mb-3">
                <div class="card-header p-1 border-0">
                  <h4 class="mb-0">Amount {{ wallet_from_currency }}</h4>
                </div>
                <div class="mt-2">
                  <el-form ref="formData" :model="formData" :rules="formRules">
                    <el-form-item label="" prop="amount">
                      <el-input
                        class="big-input"
                        type="number"
                        placeholder="Please enter amount"
                        v-model="formData.amount"
                        @input="amountEnter()"
                      >
                      </el-input>
                    </el-form-item>
                  </el-form>
                </div>
                <div>
                  <QuoteButton
                    ref="quotebtn"
                    :customertype="'normal'"
                    :from="wallet_from_currency"
                    :to="wallet_to_currency"
                    :amount="formData.amount"
                    :fx="true"
                    @onQuoted="onQuoted"
                    @onTimeout="onTimeout"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 text-center" v-if="quoted">
              <el-button type="primary" round :loading="submitting" :disabled="submitting" @click="onSubmit"
                >Confirm</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReviewRateDialog ref="reviewratedialog" @onClose="gotoStatement"/>
  </div>
</template>

<script>
import WalletSelect from "@/views/Wallet/components/WalletSelect/WalletSelect.vue";
import QuoteButton from "@/views/Wallet/components/QuoteButton.vue";
import WalletPayment from "@/api/wallet/payment";
import AccountApi from "@/api/wallet/account"
import ReviewRateDialog from '@/components/Review/Review'
export default {
  components: {
    WalletSelect,
    QuoteButton,
    ReviewRateDialog
  },
  computed:{
    reviewState() {
      return this.$store.getters.reviewstate;
    },
  },
  data() {
    return {
      submitting: false,
      wallet_from_currency: "",
      wallet_to_currency: "",
      quoted: false,
      loading: false,
      rateofftime: false,
      rateofftime_message: "",
      kycStatus: "",
      formData: {
        from_bankholderid: "",
        to_bankholderid: "",
        amount: "",
        verifytoken: "",
        qoutetoken: "",
        calresponse: "",
      },
      formRules: {
        amount: [{ required: true, message: "Please enter amount", trigger: "blur" }],
      },
    };
  },
  methods: {
    getKyc() {
      AccountApi.getKycState().then(({ result, data }) => {
        if (result) {
          this.kycStatus = data['status'];
        }
      })
    },
    paymentOfftime() {
      this.loading = true;
      WalletPayment.paymentOfftime().then(({ result, data, message }) => {
        this.loading = false;
        if (result) {
          this.rateofftime = data["offtime"];
          this.rateofftime_message = data["message"];
        }
      });
    },
    selectFrom(wallet) {
      this.formData.from_bankholderid = wallet.bankholderID;
      this.wallet_from_currency = wallet.currency;
      this.$emit("changeFrom", wallet.currency);
    },
    selectTo(wallet) {
      this.formData.to_bankholderid = wallet.bankholderID;
      this.wallet_to_currency = wallet.currency;
      this.$emit("changeTo", wallet.currency);
    },
    amountEnter() {
      this.$refs.quotebtn.clearTimer();
    },
    onQuoted(data) {
      this.formData.verifytoken = data["verifytoken"];
      this.formData.qoutetoken = data["qoutetoken"];
      this.formData.calresponse = data["response"];
      this.quoted = true;
      this.$emit("reloadRate");
    },
    onTimeout() {
      this.quoted = false;
    },

    onSubmit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.submitting = true;
          let model = JSON.parse(JSON.stringify(this.formData));
          model.amount = parseFloat(this.formData.amount);
          WalletPayment.autoFX(model).then(
            ({ result, message }) => {
              this.submitting = false;
              this.amountEnter();
              if (result) {
                this.$swal(
                  `Message`,
                  `Auto FX between your account is success`,
                  "success"
                ).then(() => {
                  if(this.reviewState){
                    this.gotoStatement();
                  }else{
                    this.showReview();
                  }
                });
              } else {
                this.$swal("Message", message, "error");
              }
            },
            (err) => {
              this.amountEnter();
              if (err.response) {
                this.$swal("Message", err.response.data.message, "error");
              }
            }
          );
        }
      });
    },
    gotoStatement(){
      this.$router.push({
        path: `/wallet/statement?bankholderid=${this.formData.from_bankholderid}`,
      });
    },
    showReview(){
      var paymentReceipt = {
        payment_UID : this.formData.qoutetoken,
        currency_from: this.formData.calresponse.from.toUpperCase(),
        currency_to : this.formData.calresponse.to.toUpperCase(),
        amount: this.formData.calresponse.amountFrom,
        receive: this.formData.calresponse.amountTo,
        rate: this.formData.calresponse.rate
      }
      this.$refs.reviewratedialog.show('autofx', paymentReceipt)
    }
  },
  mounted() {
    this.getKyc();
    this.paymentOfftime();
    if (this.$route.query.from) {
      this.$refs.walletFrom.show(this.$route.query.from.toUpperCase());
    }
  },
};
</script>

<style lang="scss"></style>
