import request from '../request'
import Auth from '../auth'
import {
  uuid
} from 'vue-uuid'
const WalletPayment = {
  paymentPending(data) { 
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/payment/wallet/pending",
      method: "post",
      data: data
    });
  },
  paymentHistory(data) {
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/payment/wallet/history",
      method: "post",
      data: data
    });
  },
  paymentReceipt(paymentuid) {
    return request({
      url: `/v1/payment/wallet/receipt/${paymentuid}`,
      method : 'get'
    })
  },
  paymentWallet(data) { 
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/payment/wallet",
      method: "post",
      data: data
    })
  },
  paymentOfftime() { 
    return request({
      url: "/v1/payment/wallet/offtime",
      method: "get",
    })
  },
  autoFX(data) {
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/fx/wallet/auto",
      method: "post",
      data: data
    });
  },
  downloadPaymentReceipt(payment_uid, filename) {
    const token = Auth.getToken()
    const baseURL = (process.env.VUE_APP_SERVER_BASE_API || "") + process.env.VUE_APP_SERVER_BASE_API_VERSION
    const link = document.createElement('a')
    link.href = `${baseURL}/v1/payment/pdf/${payment_uid}?access_token=${token}`;
    if (filename) {
      link.download = filename
    }
    link.click()
  },
}

export default WalletPayment